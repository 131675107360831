import classNames from "classnames";
import React, { useRef, useState } from "react";
import { GithubPicker } from "react-color";

import { useOutsideClick } from "../hooks";
import styles from "./ColorPicker.module.css";
import Tooltip from "./Toolbar/Tooltip";
import nocolorIcon from "./nocolor.svg";
import whiteIcon from "./white.svg";

interface ColorButtonProps {
  color?: string;
  updateColor: (color: string) => void;
  colors: string[];
  pickerPosition: "left" | "right";
  className?: string;
  tooltip?: string;
}

export function ColorButton(props: ColorButtonProps) {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null!);

  useOutsideClick(buttonRef, () => setPickerVisible(false));

  const handleClick = () => {
    setPickerVisible(!pickerVisible);
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={buttonRef}
        onClick={handleClick}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        className={classNames(styles.button, props.className)}
      >
        {props.color && (
          <div
            className={styles.colorBox}
            style={
              props.color === "transparent"
                ? { backgroundImage: `url(${nocolorIcon})` }
                : props.color === "#ffffff"
                ? { backgroundImage: `url(${whiteIcon})` }
                : { backgroundColor: props.color }
            }
          />
        )}
      </div>
      {props.tooltip && (
        <Tooltip text={props.tooltip} tooltipVisible={tooltipVisible} />
      )}
      {pickerVisible && (
        <div
          className={classNames(
            styles.pickerContainer,
            styles[`pickerContainer--${props.pickerPosition}`]
          )}
        >
          <GithubPicker
            triangle={
              props.pickerPosition === "left" ? "top-left" : "top-right"
            }
            colors={props.colors}
            color={props.color}
            onChange={(color, event) => {
              event.stopPropagation();
              props.updateColor(color.hex);
              setPickerVisible(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

interface Props {
  color: string;
  updateColor: (color: string) => void;
  colors: string[];
  label?: string;
  isValid?: boolean;
  validationMessage?: string;
  style?: React.CSSProperties;
}

export default function ColorPicker(props: Props) {
  return (
    <div className={styles.container} style={props.style}>
      <label className={styles.label}>{props.label}</label>
      <ColorButton
        color={props.color}
        updateColor={props.updateColor}
        colors={props.colors}
        pickerPosition="left"
        className={styles.styledButton}
      />
      {props.isValid === false && props.validationMessage && (
        <div className={styles.error}>{props.validationMessage}</div>
      )}
    </div>
  );
}
