import "chart.js/auto";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import React, { useMemo, useState } from "react";

import { DashBoardLocationsData } from "../common/ApiService";
import { useIsDesktop } from "../common/utils";
import DashBoardLocationsSkeleton from "../components/Skeletons/DashBoardLocationsSkeleton copy";
import Pagination from "../tasks/pages/TaskTablePage/Pagination";
import { EmptyDataCap, GearButton } from "./DashBoard";
import styles from "./DashBoard.module.css";
import Submenu from "./SubMenu";

const checkDataObjIsEmpty = (data: Record<string, number>) => {
  return Object.keys(data).every((key) => {
    return data[key] === 0;
  });
};

function Locations({
  sentToBroadCast,
  toggleLocationsBroadcast,
  atmoLocations,
  isLoading,
}: {
  sentToBroadCast: boolean;
  toggleLocationsBroadcast: () => void;
  atmoLocations: DashBoardLocationsData;
  isLoading: boolean;
}) {
  const [isLocationGearSubmenuVisible, setIsLocationGearSubmenuVisible] =
    useState(false);
  const [locationGearSelectedOption, setLocationGearSelectedOption] =
    useState<string>("Количество");
  const [frequency, setFrequency] = useState<string>("frequently");
  const [displayedPage, setDisplayedPage] = useState<number>(1);

  const isDesktop = useIsDesktop();
  const PERPAGE = 10;
  const gearOptions = ["Количество", "Проценты"];

  function shortenString(input: string, maxLength: number): string {
    if (input.length <= maxLength) {
      return input;
    } else {
      const shorted = input.substring(0, maxLength);
      return `${shorted}...`;
    }
  }

  function perсentData(data: Record<string, number>) {
    const newObject: Record<string, number> = {};
    let amount = 0;
    Object.values(data).forEach((value) => {
      amount += value;
    });
    Object.keys(data).forEach((key) => {
      const percentage = Number(((data[key] / amount) * 100).toFixed(1));
      newObject[key] = percentage;
    });
    return newObject;
  }

  const isAtmoLocationsEmpty = useMemo(() => {
    const results = [];
    for (const key in atmoLocations) {
      results.push(checkDataObjIsEmpty(atmoLocations[key]));
    }
    return results.every((res) => res === true);
  }, [atmoLocations]);

  const modifiedAtmoLocations = useMemo(() => {
    const entries: [string, { [key: string]: number }][] =
      Object.entries(atmoLocations);
    const modifiedObject: { [key: string]: number } = {};
    entries.forEach((entry) => {
      Object.keys(entry[1]).forEach((key) => {
        const modifiedKey = `${entry[0]}: ${key}`;
        modifiedObject[modifiedKey] = entry[1][key];
      });
    });
    return modifiedObject;
  }, [atmoLocations]);

  const sortedAtmoLocations = useMemo(() => {
    const entries = Object.entries(modifiedAtmoLocations);
    const sortedEntries = entries.sort(([, value1], [, value2]) => {
      if (frequency === "frequently") {
        return value2 - value1;
      } else {
        return value1 - value2;
      }
    });
    setDisplayedPage(1);
    return Object.fromEntries(sortedEntries);
  }, [modifiedAtmoLocations, frequency]);

  const perсentAtmoLocations = useMemo(() => {
    return perсentData(sortedAtmoLocations);
  }, [sortedAtmoLocations]);

  const pageCount = Math.ceil(
    Object.keys(sortedAtmoLocations).length / PERPAGE
  );

  const toggleLocationMenu = () => {
    setIsLocationGearSubmenuVisible((prev) => !prev);
  };

  const handleLocationOption = (option: string) => {
    setLocationGearSelectedOption(option);
    toggleLocationMenu();
  };

  const changePages = (value: number) => {
    setDisplayedPage(value);
  };

  const displayedAtmoLocations = useMemo(() => {
    const start = (displayedPage - 1) * PERPAGE;
    const end = start + PERPAGE;
    const result = Object.keys(sortedAtmoLocations).slice(start, end);
    return result;
  }, [displayedPage, sortedAtmoLocations]);

  const TableBlock = () => (
    <>
      <table style={{ marginTop: "20px" }} className={styles.locationTable}>
        <thead>
          <tr className={styles.locationTableHeader}>
            <th>Локация</th>
            <th>Частота использования</th>
          </tr>
        </thead>
      </table>
      <table className={styles.locationTable}>
        <tbody>
          {displayedAtmoLocations.map((location: string) => (
            <tr key={location} className={styles.tableHeader}>
              <td>
                {isDesktop
                  ? shortenString(location, 57)
                  : shortenString(location, 46)}
              </td>
              <td>
                {locationGearSelectedOption === "Проценты"
                  ? `${perсentAtmoLocations[location]}%`
                  : sortedAtmoLocations[location]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );

  const DisplayBlock = () => {
    if (isLoading) {
      return <DashBoardLocationsSkeleton />;
    }

    if (!!isAtmoLocationsEmpty) {
      return <EmptyDataCap />;
    }

    return <TableBlock />;
  };

  return (
    <>
      <div className={styles.blocksContainer}>
        <section className={styles.locationContainer}>
          <div>
            <div className={styles.titleContainer}>
              <div className={styles.title}>
                {"Частота использования локаций"}
              </div>
              <div>
                <GearButton
                  toggleMenu={toggleLocationMenu}
                  isGearOn={isLocationGearSubmenuVisible}
                />
                {isLocationGearSubmenuVisible && (
                  <Submenu
                    options={gearOptions}
                    selectedOption={locationGearSelectedOption}
                    handleOptionClick={handleLocationOption}
                  />
                )}
              </div>
            </div>
            <div className={styles.selectContainer}>
              <Checkbox
                label="Только эфирные"
                checked={sentToBroadCast}
                setChecked={toggleLocationsBroadcast}
              />
              <Select
                name="frequency"
                options={[
                  { id: "frequently", name: "Самые используемые" },
                  { id: "rare", name: "Самые редкие" },
                ]}
                value={frequency}
                onChange={(event) => setFrequency(event.target.value)}
              />
            </div>
          </div>
          <DisplayBlock />
          <div>
            <Pagination
              tasks={displayedAtmoLocations}
              isStepCounterDisabled={!isDesktop}
              totalTasks={
                isDesktop ? Object.keys(sortedAtmoLocations).length : undefined
              }
              counterTitle={"Локаций"}
              page={displayedPage}
              perPage={PERPAGE}
              totalPages={pageCount}
              changePage={(value) => changePages(value)}
              style={{
                marginTop: "15px",
                float: "none",
                justifyContent: "center",
              }}
            />
          </div>
        </section>
      </div>
    </>
  );
}

export default React.memo(Locations);
