import rgbHex from "rgb-hex";

const SIZE_INCREASER = 3;

function transformHTMLforUnity(node: ChildNode) {
  let sub = "";
  node.childNodes.forEach((node) => {
    sub += transformHTMLforUnity(node);
  });

  switch (node.nodeName) {
    case "P":
      return `${sub}\n`;
    case "EM":
      return `<I>${sub}</I>`;
    case "STRONG":
      return `<B>${sub}</B>`;
    case "SPAN": {
      const el = node as HTMLElement;
      const style = el.getAttribute("style");
      const matchColor = /color: ?(rgb\(.+\))?/;
      const matchSize = /font-size: ?(\w+);?/;
      let color = "";
      let size = "";
      let sizeDigits: number;

      if (/font-size/.exec(style!) && /color/.exec(style!)) {
        color = matchColor.exec(style!)![1];
        try {
          color = rgbHex(color);
        } catch (error) {
          //nothing
        }
        size = matchSize.exec(style!)![1];
        sizeDigits = parseInt(size) * 3;
        return `<COLOR=#${color}><SIZE=${sizeDigits}>${sub}</SIZE></COLOR>`;
      } else {
        const styleAttr = style!.replace(";", "");
        let styleType = styleAttr.split(":")[0];

        if (styleType === "color") {
          color = matchColor.exec(style!)![1];
          color = rgbHex(color);
          return `<COLOR=#${color}>${sub}</COLOR>`;
        } else if (styleType === "font-size") {
          size = matchSize.exec(style!)![1];
          sizeDigits = parseInt(size) * SIZE_INCREASER;
          return `<SIZE=${sizeDigits}>${sub}</SIZE>`;
        } else {
          return sub;
        }
      }
    }
    case "#text":
      return node.textContent;
    default:
      return sub;
  }
}

export function convertHtmlforUnity(html: string) {
  const parent = document.createElement("template");
  parent.innerHTML = html;
  let out = "";
  parent.content.childNodes.forEach((node) => {
    out += transformHTMLforUnity(node);
  });
  return out.trim();
}
