import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import TableEditor, {
  TableData,
  createCell,
} from "../../common/TableEditorNew";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  MAX_TIMING,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateMaxLines,
  validateNotEmpty,
  validatePositive,
} from "../util";

function defaultTableConstructor(cols = 3): TableData {
  const rowData = [Array.from({ length: cols }, () => ({ ...createCell() }))];
  const columnData = Array.from({ length: cols }, () => ({
    width: parseFloat((100 / cols).toFixed(2)),
  }));

  return {
    columnData,
    rowData,
  };
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    tableData: (initialFormData?.tableData ??
      defaultTableConstructor()) as TableData,
    tableVisible: false,
  };
}

export type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    title: validateNotEmpty(values.title) ?? validateMaxLines(values.title, 2),
    timing:
      validatePositive(values.timing) ?? validateMax(values.timing, MAX_TIMING),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    title: values.title,
    subtitle: values.subtitle,
    source: values.source,
    timing: +values.timing,
    tableData: values.tableData,
  };
}

function M24TableForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  const { values, errors, touched, setFieldValue, isValid, isSubmitting } =
    formik;

  usePreview(channel, template, values.timing, values, prepareData);

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: isSubmitting,
    isValid: isValid,
    prepareData,
    values: values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          {...getValidationProps("timing", touched, errors)}
          required
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          rows={2}
          required
          {...getValidationProps("title", touched, errors)}
        />
        <Field as={TextArea} name="subtitle" label="Подзаголовок" rows={2} />
        <Field as={TextField} label="Источник" name="source" />
        <button
          type="button"
          style={{
            marginLeft: isDesktop ? 210 : 0,
            marginBottom: "1rem",
            fontSize: 18,
            border: "none",
            padding: 0,
            background: "transparent",
            color: "var(--blue)",
            cursor: "pointer",
          }}
          onClick={() => setFieldValue("tableVisible", true)}
        >
          Редактировать таблицу
        </button>
        <TableEditor
          // tableData={values.tableData}
          updateData={(tableData) => setFieldValue("tableData", tableData)}
          visible={values.tableVisible}
          close={() => setFieldValue("tableVisible", false)}
          defaultTableConstructor={defaultTableConstructor}
          additionalInfo="Тег цвета и стрелки: [r] - красный, [b] - синий, [g] - зеленый, [+] - стрелка вверх, [-] - стрелка вниз"
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24TableForm);
