import { cloneDeep } from "lodash";

import { feedbackFilters } from "../../../common/texts";
import { Feedback, IFilterState } from "./FilterTypes";

const FILTER_LOCALSTORAGE_KEY = "new_filter_state";

export const substValueByBoolean = (obj: Record<string, any>) => {
  const objCopy = cloneDeep(obj);

  function subst(obj: any) {
    for (const key in obj) {
      if (typeof obj[key] === "object") {
        subst(obj[key]);
      } else {
        obj[key] = false;
      }
    }
    return obj;
  }

  return subst(objCopy);
};

export function getIdsOfAllTemplates<T extends string | number>(
  templatesByChannels: Record<string, Record<T, string>>
) {
  let allTemplatesArray = [];
  for (const channel of Object.keys(templatesByChannels)) {
    for (const template in templatesByChannels[channel]) {
      allTemplatesArray.push(template);
    }
  }
  return allTemplatesArray;
}

function isValidFilterState(obj: IFilterState) {
  if (
    typeof obj.status !== "string" ||
    !Array.isArray(obj.templates) ||
    !Array.isArray(obj.bitrixTemplates) ||
    typeof obj.feedback !== "object" ||
    typeof obj.order !== "string" ||
    !["asc", "desc"].includes(obj.order) ||
    typeof obj.page !== "number" ||
    typeof obj.perPage !== "number" ||
    typeof obj.allUsers !== "boolean" ||
    !["week", "month", "year", ""].includes(obj.periodPreset)
  ) {
    return false;
  }
  if (!obj.templates.every((item) => typeof item === "string")) {
    return false;
  }
  if (!obj.bitrixTemplates.every((item) => typeof item === "number")) {
    return false;
  }
  if (
    typeof obj.feedback.sentToBroadcast !== "boolean" ||
    typeof obj.feedback.renderError !== "boolean" ||
    typeof obj.feedback.incorrectData !== "boolean"
  ) {
    return false;
  }
  return true;
}

export function getDefaultState(): IFilterState {
  return {
    status: "all",
    templates: [],
    bitrixTemplates: [],
    feedback: substValueByBoolean(feedbackFilters) as Feedback,
    order: "desc",
    page: 1,
    perPage: 20,
    allUsers: false,
    startDate: undefined,
    endDate: undefined,
    periodPreset: "",
  };
}

export function restoreFilterState(): IFilterState {
  const filterState = localStorage.getItem(FILTER_LOCALSTORAGE_KEY);
  const defaultState = getDefaultState();

  if (filterState) {
    const parsedFilterState = JSON.parse(filterState);

    if (!isValidFilterState(parsedFilterState)) {
      return defaultState;
    }

    if (parsedFilterState.startDate)
      parsedFilterState.startDate = new Date(parsedFilterState.startDate);

    if (parsedFilterState.endDate)
      parsedFilterState.endDate = new Date(parsedFilterState.endDate);

    return parsedFilterState;
  } else {
    return defaultState;
  }
}

export function saveFilterState(filterState: IFilterState) {
  localStorage.setItem(FILTER_LOCALSTORAGE_KEY, JSON.stringify(filterState));
}

export function arrayContainsAllElements(arr1: Array<any>, arr2: Array<any>) {
  return arr2.every((elem) => arr1.includes(elem));
}

export function arrayContainsSomeElements(arr1: Array<any>, arr2: Array<any>) {
  return arr2.some((elem) => arr1.includes(elem));
}

export function deletedKeyNewArray(array: Array<any>, key: string | number) {
  const nextVal = [...array];
  const keysSet = new Set(nextVal);
  keysSet.delete(key);
  return [...keysSet];
}

export function addedKeyNewArray(
  array: (string | number)[],
  key: string | number
) {
  const nextVal = [...array, key];
  const keysSet = new Set(nextVal);
  return [...keysSet];
}

export function addedGroupNewArray(
  array: (string | number)[],
  groupObject: Record<string | number, string>,
  numeric: boolean = false
) {
  const keysArray = numeric
    ? Object.keys(groupObject).map(Number)
    : Object.keys(groupObject);

  const nextVal = [...array, ...keysArray];

  const keysSet = new Set(nextVal);
  return [...keysSet];
}

export function deletedGroupNewArray(
  array: (string | number)[],
  groupObject: Record<string | number, string>,
  numeric: boolean = false
) {
  const keysArray = numeric
    ? Object.keys(groupObject).map(Number)
    : Object.keys(groupObject);
  const nextVal = [...array].filter(
    (item) => !keysArray.includes(item as never)
  );
  const keysSet = new Set(nextVal);

  return [...keysSet];
}
