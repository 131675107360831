import DatePeriod from "emg-ui-kit/components/DateTime/DatePeriod";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "../common/PageContainer";
import {
  dashBoardData,
  getDashBoardLocationsDataThunk,
  getDashBoardMapDataThunk,
  getTotalBroadcastThunk,
} from "../redux/dashBoard";
import styles from "./DashBoard.module.css";
import InteractiveMaps from "./InteractiveMaps";
import Locations from "./Locations";

export const EmptyDataCap = () => (
  <div className={styles.emptyDataCap}>
    За выбранный период отсутствуют данные
  </div>
);

function getStringExpressionFromDate(date: Date) {
  if (!date) {
    return undefined;
  }
  const yaer = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${yaer}-${month}-${day}`;
}

export function GearButton({
  toggleMenu,
  isGearOn,
}: {
  toggleMenu: () => void;
  isGearOn: boolean;
}) {
  return (
    <button
      className={isGearOn ? styles.gearButtonOn : styles.gearButton}
      onClick={() => toggleMenu()}
    ></button>
  );
}

export default function DashBoard() {
  const [period, setPeriod] = useState("1");
  const dispatch = useDispatch();
  const {
    atmoMaps,
    atmoLocations,
    isLocationsLoading,
    isInteractiveLoading,
    totalBroadcast,
  } = useSelector(dashBoardData);

  const [sentToBroadCast, setSentToBroadCast] = useState({
    interactive: true,
    locations: true,
  });

  const [periodDates, setPeriodDates] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });

  const toggleInteractiveBroadcast = useCallback(() => {
    setSentToBroadCast((prev) => ({ ...prev, interactive: !prev.interactive }));
  }, []);

  const toggleLocationsBroadcast = useCallback(() => {
    setSentToBroadCast((prev) => ({ ...prev, locations: !prev.locations }));
  }, []);

  const dateString: string = useMemo(() => {
    if (period === "custom") {
      const startDateString =
        periodDates.startDate &&
        getStringExpressionFromDate(periodDates.startDate);
      const endDateString =
        periodDates.endDate && getStringExpressionFromDate(periodDates.endDate);
      let resultString = "";
      resultString += startDateString ? `startDate=${startDateString}` : "";
      resultString += startDateString ? "&" : "";
      resultString += endDateString ? `endDate=${endDateString}` : "";
      return resultString;
    } else if (period !== "-") {
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const endDate = `${year}-${month}-${day}`;
      const currentMonth = currentDate.getMonth() + 1;
      let newMonth = currentMonth - +period;
      if (newMonth < 1) {
        year -= 1;
        newMonth += 12;
      }
      const startMonth = String(newMonth).padStart(2, "0");
      const startDate = `${year}-${startMonth}-${day}`;
      const dataString = `startDate=${startDate}&endDate=${endDate}`;
      return dataString;
    } else {
      return "";
    }
  }, [period, periodDates]);

  const S2BC = "&sentToBroadcast=1";

  useEffect(() => {
    const locationsParams =
      dateString + (sentToBroadCast.locations ? S2BC : "");
    dispatch(getDashBoardLocationsDataThunk(locationsParams));
  }, [dispatch, dateString, sentToBroadCast.locations]);

  useEffect(() => {
    const interactiveParams =
      dateString + (sentToBroadCast.interactive ? S2BC : "");
    dispatch(getDashBoardMapDataThunk(interactiveParams));
  }, [dispatch, dateString, sentToBroadCast.interactive]);

  useEffect(() => {
    dispatch(getTotalBroadcastThunk(dateString));
  }, [dispatch, dateString]);

  return (
    <PageContainer className={styles.pageContainer}>
      <Title text="Дэшборд" />
      <div className={styles.selectContainer}>
        <div style={{ minWidth: 300 }}>
          <Select
            name="period"
            options={[
              { id: "1", name: "Последний месяц" },
              { id: "3", name: "Последние 3 месяца" },
              { id: "6", name: "Последние 6 месяцев" },
              { id: "12", name: "Последний год" },
              { id: "-", name: "Весь период" },
              { id: "custom", name: "Произволный" },
            ]}
            value={period}
            onChange={(event) => {
              setPeriod(event.target.value);
              setPeriodDates({ startDate: undefined, endDate: undefined });
            }}
          />
        </div>
        <div style={{ minWidth: 300 }}>
          <DatePeriod
            startDate={periodDates.startDate}
            endDate={periodDates.endDate}
            name="date-period"
            handleStartDateChange={(value) => {
              setPeriod("custom");
              setPeriodDates({ ...periodDates, startDate: value });
            }}
            handleEndDateChange={(value) => {
              setPeriod("custom");
              setPeriodDates({ ...periodDates, endDate: value });
            }}
          />
        </div>
      </div>
      <span style={{ display: "flex", gap: 7 }}>
        Всего эфирных заказов:{"  "}
        <div className={styles.title}>{totalBroadcast}</div>
      </span>
      <div className={styles.blocksContainer}>
        <InteractiveMaps
          atmoMaps={atmoMaps}
          isLoading={isInteractiveLoading}
          toggleInteractiveBroadcast={toggleInteractiveBroadcast}
          sentToBroadCast={sentToBroadCast.interactive}
        />
        <Locations
          sentToBroadCast={sentToBroadCast.locations}
          atmoLocations={atmoLocations}
          toggleLocationsBroadcast={toggleLocationsBroadcast}
          isLoading={isLocationsLoading}
        />
      </div>
    </PageContainer>
  );
}
