import Button from "emg-ui-kit/components/Button";
import ImageInput from "emg-ui-kit/components/ImageInput";
import Modal from "emg-ui-kit/components/Modal";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import { PersonData } from "../common/ApiService";
import BackButton from "../common/BackButton";
import FlexContainer from "../common/FlexContainer";
import PageContainer from "../common/PageContainer";
import { convertImageUrlToFile, useIsDesktop } from "../common/utils";
import yup from "../common/yup";
import { IMAGE_TYPES, ValidationPropsUtils } from "../order/util";
import {
  deletePersonByIdThunk,
  getPersonByIdThunk,
  updatePersonByIdThunk,
} from "../redux/persons";
import { selectPersonById } from "../redux/persons/selectors";
import routes from "../routes";
import { AppDispatch } from "../store";

const initialValues: PersonData = {
  name: "",
  position: "",
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

const convertPhotoIfExists = async (photo?: string) => {
  return photo ? await convertImageUrlToFile(photo) : undefined;
};

export default function PersonChangeForm() {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch = useDispatch<AppDispatch>();
  const person = useSelector(selectPersonById(id));

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(updatePersonByIdThunk({ id, ...values })).then((result) => {
        if ("error" in result) return;
        history.push(routes.persons);
      });
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(getPersonByIdThunk(id));
    }
  }, [dispatch, id]);

  const { setValues } = formik;
  useEffect(() => {
    if (!person) return;
    (async () => {
      await setValues({
        name: person.name,
        position: person.position ?? "",
        photo: await convertPhotoIfExists(person.photo),
        photoAlpha: await convertPhotoIfExists(person.photoAlpha),
        photoRect: await convertPhotoIfExists(person.photoRect),
        photoM24: await convertPhotoIfExists(person.photoM24),
        photoVip: await convertPhotoIfExists(person.photoVip),
      });
    })();
  }, [setValues, person]);

  const showDeleteModal = () => setDeleteModalVisible(true);
  const hideDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = () => {
    hideDeleteModal();
    setTimeout(() => {
      dispatch(deletePersonByIdThunk(id));
      history.push(routes.persons);
    }, 100);
  };

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const buttonStyle = { marginBottom: 10 };
  const buttonContainerStyle: React.CSSProperties = {
    flexDirection: isDesktop ? "row" : "column",
  };

  return (
    <PageContainer>
      <BackButton to={routes.persons} />
      <Title text="Редактирование записи" />
      <FormikProvider value={formik}>
        <Form>
          <Field
            as={TextField}
            label="Имя"
            name="name"
            required
            {...validationUtils.getProps("name")}
          />
          <Field as={TextField} label="Должность" name="position" />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photo}
            updateImage={(image) => formik.setFieldValue("photo", image)}
            label="Фото"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoAlpha}
            updateImage={(image) => formik.setFieldValue("photoAlpha", image)}
            label="Фото (alpha)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoRect}
            updateImage={(image) => formik.setFieldValue("photoRect", image)}
            label="Фото (прямоугольное)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoM24}
            updateImage={(image) => formik.setFieldValue("photoM24", image)}
            label="Фото (M24)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoVip}
            updateImage={(image) => formik.setFieldValue("photoVip", image)}
            label="Фото (VIP)"
          />
          <FlexContainer style={buttonContainerStyle}>
            <Button
              title="Сохранить"
              type="submit"
              status={formik.isSubmitting ? "loading" : "enabled"}
              style={buttonStyle}
            />
            <Button
              color="red"
              title="Удалить"
              onClick={showDeleteModal}
              style={buttonStyle}
            />
          </FlexContainer>
          <Modal visible={deleteModalVisible} close={hideDeleteModal}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              <div>
                Вы точно хотите удалить запись <strong>{person?.name}</strong>
              </div>
              <br />
              <FlexContainer style={buttonContainerStyle}>
                <Button
                  title="Назад"
                  onClick={hideDeleteModal}
                  style={buttonStyle}
                />
                <Button
                  title="Удалить"
                  color="red"
                  onClick={handleDelete}
                  style={buttonStyle}
                />
              </FlexContainer>
            </div>
          </Modal>
        </Form>
      </FormikProvider>
    </PageContainer>
  );
}
