import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import {
  DashBoardMapData,
  getDashBoardMapData,
  DashBoardLocationsData,
  getDashBoardLocationsData,
  getTotalAtmoBroadcast,
} from "../../common/ApiService";
import { RootState } from "../../store";

export interface DashBoardDataState {
  isInteractiveLoading: boolean;
  isLocationsLoading: boolean;
  dashBoardMapData: DashBoardMapData;
  dashBoardLocationsData: DashBoardLocationsData;
  totalBroadcast: number;
}

const initialState: DashBoardDataState = {
  isInteractiveLoading: false,
  isLocationsLoading: false,
  dashBoardMapData: {},
  dashBoardLocationsData: {},
  totalBroadcast: 0,
};

export const getDashBoardMapDataThunk = createAsyncThunk(
  "dashboard/fetchMapData",
  getDashBoardMapData
);
export const getDashBoardLocationsDataThunk = createAsyncThunk(
  "dashboard/fetchLocationsData",
  getDashBoardLocationsData
);

export const getTotalBroadcastThunk = createAsyncThunk(
  "dashboard/fetchTotalBroadcast",
  getTotalAtmoBroadcast
);

const dashBoardDataReducer = createReducer(initialState, (builder) => {
  builder.addCase(getDashBoardMapDataThunk.fulfilled, (state, action) => {
    state.dashBoardMapData = action.payload;
    state.isInteractiveLoading = false;
  });
  builder.addCase(getDashBoardMapDataThunk.pending, (state, action) => {
    state.isInteractiveLoading = true;
  });
  builder.addCase(getTotalBroadcastThunk.fulfilled, (state, action) => {
    state.totalBroadcast = action.payload.total;
  });

  builder.addCase(getDashBoardLocationsDataThunk.fulfilled, (state, action) => {
    state.dashBoardLocationsData = action.payload;
    state.isLocationsLoading = false;
  });
  builder.addCase(getDashBoardLocationsDataThunk.pending, (state, action) => {
    state.isLocationsLoading = true;
  });
});

export default dashBoardDataReducer;

export const dashBoardData = (state: RootState) => ({
  isInteractiveLoading: state.dashBoardData.isInteractiveLoading,
  isLocationsLoading: state.dashBoardData.isLocationsLoading,
  atmoMaps: state.dashBoardData.dashBoardMapData,
  atmoLocations: state.dashBoardData.dashBoardLocationsData,
  totalBroadcast: state.dashBoardData.totalBroadcast,
});
