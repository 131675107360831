import { configureStore } from "@reduxjs/toolkit";

import atmoParamsReducer from "./redux/atmoParams";
import authReducer from "./redux/auth";
import authMiddleware from "./redux/auth/middleware";
import backupReducer from "./redux/backup";
import bitrixReducer from "./redux/bitrix/bitrix.reducer";
import dashBoardDataReducer from "./redux/dashBoard";
import draftReducer from "./redux/drafts";
import emailParamsReducer from "./redux/emailParams";
import { customErrors } from "./redux/error";
import errorReducer from "./redux/error/reducer";
import filterReducer from "./redux/filters/filters.reducer";
import newsReducer from "./redux/news";
import { notificationReducer } from "./redux/notifications";
import personsReducer from "./redux/persons";
import tasksReducer from "./redux/tasks";
import usePreviewReducer from "./redux/usePreview";
import usersReducer from "./redux/users";
import viewParamsReducer from "./redux/viewParams";

const store = configureStore({
  reducer: {
    atmoParams: atmoParamsReducer,
    auth: authReducer,
    backup: backupReducer,
    drafts: draftReducer,
    emailParams: emailParamsReducer,
    errors: errorReducer,
    customErrors: customErrors,
    news: newsReducer,
    notifications: notificationReducer,
    persons: personsReducer,
    tasks: tasksReducer,
    usePreview: usePreviewReducer,
    users: usersReducer,
    viewParams: viewParamsReducer,
    dashBoardData: dashBoardDataReducer,
    bitrix: bitrixReducer,
    filter: filterReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }).concat([authMiddleware]),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export default store;
