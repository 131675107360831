import React from "react";

import {
  atmoNumberBlockTypes,
  timeOfDayTypes,
  atmoWeatherTypes,
} from "../../../../../order/atmosphere/constants";
import { ColorBox, InfoRow } from "../InfoComponents";

export function Atmosphere({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      {data.sheduleOfBroadcast && (
        <InfoRow title="Время эфира" value={data.sheduleOfBroadcast} />
      )}
      <InfoRow title="Ручная сборка" value={data.manual ? "Да" : "Нет"} />
      {(data.blocks as Record<string, any>[])?.map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Комментарий" value={block.comment} />
          <InfoRow
            title="Тип блока"
            value={atmoNumberBlockTypes[block.blockType]}
          />
          <InfoRow title="Хронометраж (сек.)" value={block.timing} />
          {block.source && <InfoRow title="Источник" value={block.source} />}

          {block.location ? (
            <>
              <InfoRow title="Локация" value={block.location.name} />
              <InfoRow title="Погода" value={atmoWeatherTypes[block.weather]} />
              <InfoRow
                title="Время суток"
                value={timeOfDayTypes[block.timeOfDay]}
              />
            </>
          ) : block.map ? (
            <InfoRow title="2D карта" value={block.map.name} />
          ) : null}

          {(() => {
            switch (block.blockType) {
              case "text":
                return (
                  <>
                    <InfoRow title="Первая строка" value={block.firstLine} />
                    {block.secondLine && (
                      <InfoRow title="Вторая строка" value={block.secondLine} />
                    )}
                    {block.thirdLine && (
                      <>
                        <InfoRow
                          title="Третья строка"
                          value={block.thirdLine}
                        />
                        <InfoRow
                          title="Цвет третьей строки"
                          value={<ColorBox color={block.thirdLineColor} />}
                        />
                      </>
                    )}
                  </>
                );
              case "feelsLike":
                return (
                  <InfoRow title="Температура" value={block.temperature} />
                );
              case "pressure":
                return <InfoRow title="Давление" value={block.pressure} />;
              case "forecast":
                return (
                  <>
                    <InfoRow
                      title="Время прогноза"
                      value={block.forecastTime}
                    />
                    <InfoRow title="Температура" value={block.temperature} />
                    <InfoRow
                      title="Подпись"
                      value={block.forecastDescription}
                    />
                  </>
                );
              case "wind":
                return (
                  <>
                    <InfoRow
                      title="Направление ветра"
                      value={block.windDirection}
                    />
                    <InfoRow title="Скорость ветра" value={block.windSpeed} />
                  </>
                );
              default:
                return null;
            }
          })()}
          {block?.videoEffect && (
            <InfoRow
              title="Видеоэффект"
              value={block?.videoEffect?.name?.replace("Видеоэффект:", "")}
            />
          )}
        </details>
      ))}
    </>
  );
}
