import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React from "react";

import BackButton from "../common/BackButton";
import FlexContainer from "../common/FlexContainer";
import PageContainer from "../common/PageContainer";
import PreviewImage from "../common/PreviewImage";
import UnityPlayer from "../common/UnityPlayer";
import { useIsDesktop } from "../common/utils";
import OrderFormSkeleton from "../components/Skeletons/OrderFormSkeleton";
import OrderFormSkeletonMoblie from "../components/Skeletons/OrderFormSkeletonMobile";
import styles from "./OrderForm.module.css";
import PreviewProvider from "./PreviewProvider";
import Subform from "./Subform";
import { useOrderForm } from "./useOrderForm";

function OrderForm() {
  const {
    showPreview,
    shouldSeparatePreview,
    isPreviewData,
    showOverlay,
    isDraft,
    channel,
    template,
    isFormLoading,
    isFormVisible,
    isPreviewAvailable,
    setShowPreview,
    isShowOverlayVisible,
    setShowOverlay,
    subformProps,
    setUsePreviewCheckedDisp,
    handleBitrixProjectChange,
    handleBitrixGraphicChange,
    projects,
    selectedProject,
    selectedGraphic,
    channelOptions,
    templateOptions,
    handleChannelChange,
    handleTemplateChange,
    isBitrixClientForm,
  } = useOrderForm();

  const isDesktopOrLaptop = useIsDesktop();
  const marginCorrection = { marginLeft: isDesktopOrLaptop ? 210 : 0 };

  return (
    <FlexContainer
      style={{ display: "flex", overflow: "auto", height: "100vh" }}
    >
      <PreviewProvider>
        {showPreview && shouldSeparatePreview && isPreviewData && (
          <section className={styles.playerSection}>
            <UnityPlayer showOverlay={showOverlay} />
          </section>
        )}

        <section className={styles.formSection}>
          <PageContainer>
            <>
              <BackButton />
              <Title
                text={`Заявка на графику${isDraft ? " (черновик)" : ""}`}
                style={{ marginTop: 50, ...marginCorrection }}
              />
              {isBitrixClientForm ? (
                <>
                  <Select
                    label="Программа"
                    name="channel"
                    options={projects || []}
                    value={String(selectedProject?.id || "")}
                    onChange={handleBitrixProjectChange}
                    required
                    isValid={!!selectedProject?.id}
                    validationMessage="Необходимо выбрать"
                  />
                  <Select
                    label="Шаблон"
                    name="template"
                    options={selectedProject?.graphics || []}
                    value={String(selectedGraphic?.id || "")}
                    onChange={handleBitrixGraphicChange}
                    required
                    isValid={!!selectedGraphic?.id}
                    validationMessage="Необходимо выбрать"
                  />
                </>
              ) : (
                <>
                  <Select
                    label="Проект"
                    name="channel"
                    options={channelOptions}
                    value={channel}
                    onChange={handleChannelChange}
                  />
                  <Select
                    label="Шаблон"
                    name="template"
                    options={templateOptions}
                    value={template}
                    onChange={handleTemplateChange}
                  />
                </>
              )}

              {isFormLoading &&
                (isDesktopOrLaptop ? (
                  <OrderFormSkeleton />
                ) : (
                  <OrderFormSkeletonMoblie />
                ))}

              {isFormVisible && (
                <>
                  {isPreviewAvailable(channel, template) && (
                    <Checkbox
                      label="Включить превью"
                      checked={showPreview}
                      setChecked={(value) => {
                        setUsePreviewCheckedDisp(value);
                        setShowPreview(value);
                      }}
                      style={marginCorrection}
                    />
                  )}

                  {showPreview && isPreviewData && (
                    <section style={marginCorrection}>
                      {!shouldSeparatePreview && (
                        <UnityPlayer showOverlay={showOverlay} />
                      )}
                    </section>
                  )}
                  {isShowOverlayVisible && (
                    <Checkbox
                      label="Показывать оформление канала"
                      checked={showOverlay}
                      setChecked={() => {
                        setShowOverlay(!showOverlay);
                      }}
                      style={marginCorrection}
                    />
                  )}
                  <PreviewImage
                    src={`/images/${template}.jpeg`}
                    style={marginCorrection}
                    alt="Шаблон"
                  />
                  <Subform {...subformProps} />
                </>
              )}
            </>
          </PageContainer>
        </section>
      </PreviewProvider>
    </FlexContainer>
  );
}

export default React.memo(OrderForm);
