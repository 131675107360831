import Button from "emg-ui-kit/components/Button";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import { getDownloadLink } from "../../../common/ApiService";

const download = (link: string) => {
  const a = document.createElement("a");
  const filename = link.split("/").pop()!;
  a.href = link;
  a.target = "_blank";
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

export default function DownloadButton({ taskId }: { taskId: string }) {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();

  const handleClick = async () => {
    setLoading(true);
    try {
      const link = await getDownloadLink(taskId);
      download(link);
    } catch (error) {
      dispatch({ type: "GET_DOWNLOAD_LINK_ERROR", error });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Button
      title="Скачать"
      onClick={handleClick}
      status={loading ? "loading" : "enabled"}
      style={{ marginBottom: 10 }}
    />
  );
}
