import React from "react";

import { InfoRow } from "../InfoComponents";

export function M24Table({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      <InfoRow title="Заголовок" value={data.title} />
      {data.subtitles &&
        (data.subtitles as Record<string, any>[]).map((block, id) => (
          <details open key={id}>
            <summary>Подзаголовок №{id + 1}</summary>
            <InfoRow title="Подзаголовок" value={block.subtitle} />
          </details>
        ))}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Размер шрифта" value={data.fontSize} />
      <InfoRow
        title="Размер шрифта названия столбцов"
        value={data.headerFontSize}
      />
      <InfoRow title="Таблица" value="" />
      <table style={{ borderCollapse: "collapse" }}>
        <tbody>
          {data.tableData.data.map((row: string[], rowIndex: number) => (
            <tr key={rowIndex}>
              {row.map((col, colIndex) => (
                <td key={colIndex} style={{ border: "1px solid", padding: 10 }}>
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
