import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AtmosphereFormParams } from "../../common/ApiService";
import OrderSavingButtons, {
  ButtonStatus,
} from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import OrderFormSkeleton from "../../components/Skeletons/OrderFormSkeleton";
import OrderFormSkeletonMoblie from "../../components/Skeletons/OrderFormSkeletonMobile";
import { getAtmoParamsThunk } from "../../redux/atmoParams";
import { selectAtmoParams } from "../../redux/atmoParams/selectors";
import Form from "../Form";
import messages from "../messages";
import {
  CLIP_NAME_REGEXP,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  validateText,
  ValidationPropsUtils,
  removeTouched,
} from "../util";
import AtmoInfoBlock from "./blocks/atmo-info/AtmoInfoBlock";
import TotalTimingInfo from "./components/TotalTimingInfo";
import { AtmoInfoItem as Item, EmptyItem } from "./types";
import { calcTotalTiming, findLocation, initItems } from "./utils";

function createItem(): EmptyItem {
  return {
    blockType: "",
    timing: "5",
    source: "",
  };
}

function validateItem(item: Item) {
  const validateLine = (text: string, maxSymbols = 25) =>
    validateNotEmpty(text) ?? validateText(text, 1, maxSymbols);

  let validationMessages: Record<string, string | undefined> = {};
  if (item.blockType === "") {
    validationMessages = { blockType: "Выберите тип блока" };
  } else if (item.blockType !== "infographics") {
    validationMessages = {
      ...validationMessages,
      ...(item.mapVariant === "3D"
        ? {
            location: validateNotEmpty(item.location),
            weather: validateNotEmpty(item.weather),
            timeOfDay: validateNotEmpty(item.timeOfDay),
          }
        : item.mapVariant === "interactive" &&
          item.interactiveVariant === "wind"
        ? {
            interactiveWindDirection: validateNotEmpty(
              item.interactiveWindDirection
            ),
          }
        : item.mapVariant === "2D"
        ? { map: validateNotEmpty(item.map) }
        : {}),
    };
  }
  switch (item.blockType) {
    case "two-lines":
      const secondLineMaxSymbols =
        item.additionalField === "none"
          ? 20
          : item.additionalField === "icon-right"
          ? 17
          : 16;
      return {
        ...validationMessages,
        ...(item.additionalField !== "none" && {
          icon: validateNotEmpty(item.icon),
        }),
        firstLine: validateLine(item.firstLine),
        secondLine: validateLine(item.secondLine, secondLineMaxSymbols),
      };
    case "three-lines":
      const validateThirdLineAndAccent = (field?: string) => {
        const message = validateLine(item.thirdLine + item.accent, 24);
        if (message && /\d/.test(message))
          return `${message} + ${
            field === "accent"
              ? " (включая третью строку)"
              : " (включая акцент)"
          }`;
        return message;
      };

      return {
        ...validationMessages,
        firstLine: validateLine(item.firstLine),
        secondLine: validateLine(item.secondLine),
        thirdLine:
          item.additionalField === "accent"
            ? validateThirdLineAndAccent() || validateNotEmpty(item.thirdLine)
            : validateLine(item.thirdLine),
        ...(item.additionalField === "icon-right"
          ? { icon: item.icon ? undefined : messages.empty }
          : item.additionalField === "accent"
          ? {
              accent:
                validateNotEmpty(item.accent) ||
                validateThirdLineAndAccent("accent"),
            }
          : {}),
      };
    case "infographics":
      return {
        ...validationMessages,
        clip: validateNotEmpty(item.clip),
      };
    case "sunrise-sunset":
      return {
        ...validationMessages,
        time: validateIncorrectFormat(item.time, /\d{2}:\d{2}/),
      };
    default:
      return validationMessages;
  }
}

function validate(values: Values) {
  const errors = {
    clipName:
      validateNotEmpty(values.clipName) ??
      validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP),
    sheduleOfBroadcast: validateNotEmpty(values.sheduleOfBroadcast),
    items: values.items.map(validateItem),
  };
  return removeEmptyProps(errors);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    sheduleOfBroadcast: (initialFormData?.sheduleOfBroadcast ?? "") as string,
    manual: (initialFormData?.manual ?? false) as boolean,
    enableSound: (initialFormData?.enableSound ?? true) as boolean,
    items: (initialFormData?.blocks
      ? initialFormData.blocks.map((block: Record<string, any>) => {
          const copy = { ...block };
          if (block.location) {
            copy.location = block.location.id;
            if (block.videoEffect) {
              copy.videoEffect = block.videoEffect.name;
            }
          } else if (block.map) {
            copy.map = block.map.name;
          }
          return copy;
        })
      : initItems(createItem)) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values, formParams?: AtmosphereFormParams) {
  return {
    ...pick(values, "clipName", "manual", "enableSound", "sheduleOfBroadcast"),
    blocks: formParams
      ? values.items.map((item) => {
          if ("location" in item && item.location) {
            return {
              ...item,
              ...(item.videoEffect && item.videoEffect !== "empty"
                ? {
                    videoEffect: formParams.videoEffects.find(
                      (effect) => effect.name === item.videoEffect
                    ),
                  }
                : {}),
              location: {
                ...findLocation(item.location, formParams!),
                id: item.location,
              },
            };
          } else if ("map" in item && item.map) {
            return {
              ...item,
              map: formParams.mapTypes.find(({ name }) => name === item.map),
            };
          } else if (item.blockType === "infographics") {
            const infographic = formParams.infographics.find(
              (infographic) => infographic.name === item.clip
            );
            return {
              ...item,
              path: infographic?.path,
              overlap: infographic?.overlap,
            };
          } else {
            return item;
          }
        })
      : [],
  };
}

function AtmosphereInfoForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
}: FormProps) {
  const { atmoParams: formParams, isLoading } = useSelector(selectAtmoParams);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAtmoParamsThunk());
  }, [dispatch]);

  const shedulesOfBroadcast =
    formParams?.shedulesOfBroadcast.map((shedule) => ({
      id: shedule,
      name: shedule,
    })) ?? [];

  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values, formParams)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  const totalTiming = calcTotalTiming(values.items);
  const isTotalTimingCorrect = totalTiming === 25;

  const validationUtils = new ValidationPropsUtils(touched, errors);

  const isDesktop = useIsDesktop();

  if (isLoading) {
    return isDesktop ? <OrderFormSkeleton /> : <OrderFormSkeletonMoblie />;
  }

  const buttonStatus: ButtonStatus = isSubmitting
    ? "loading"
    : isTotalTimingCorrect
    ? "enabled"
    : "disabled";

  const buttonProps = {
    isValid: isValid,
    prepareData,
    formParams,
    values: values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
    status: buttonStatus,
  };
  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          required
          name="clipName"
          {...validationUtils.getProps("clipName")}
        />
        <Field
          as={Select}
          label="Время эфира"
          name="sheduleOfBroadcast"
          options={shedulesOfBroadcast}
          {...validationUtils.getProps("sheduleOfBroadcast")}
          required
        />
        <Checkbox
          label="Ручная сборка"
          checked={values.manual}
          setChecked={(value) => setFieldValue("manual", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />
        <Checkbox
          label="Включить звук"
          checked={values.enableSound}
          setChecked={(value) => setFieldValue("enableSound", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />

        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index) => (
            <AtmoInfoBlock item={item} index={index} formParams={formParams} />
          )}
        </Blocks>

        <TotalTimingInfo
          totalTiming={totalTiming}
          isTotalTimingCorrect={isTotalTimingCorrect}
        />

        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(AtmosphereInfoForm);
