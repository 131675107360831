import React from "react";

import {
  atmoInfoBlockTypes,
  sunriseSunset,
  timeOfDayTypes,
  atmoWeatherTypes,
} from "../../../../../order/atmosphere/constants";
import { findIconName } from "../../../../../order/atmosphere/utils";
import { InfoRow } from "../InfoComponents";

export function AtmosphereInfo({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      {data.sheduleOfBroadcast && (
        <InfoRow title="Время эфира" value={data.sheduleOfBroadcast} />
      )}
      <InfoRow title="Ручная сборка" value={data.manual ? "Да" : "Нет"} />
      <InfoRow title="Включить звук" value={data.enableSound ? "Да" : "Нет"} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Комментарий" value={block.comment} />
          <InfoRow
            title="Тип блока"
            value={atmoInfoBlockTypes[block.blockType]}
          />
          <InfoRow title="Хронометраж (сек.)" value={block.timing} />
          {block.source && <InfoRow title="Источник" value={block.source} />}

          {block.location ? (
            <>
              <InfoRow title="Локация" value={block.location.name} />
              <InfoRow title="Погода" value={atmoWeatherTypes[block.weather]} />
              <InfoRow
                title="Время суток"
                value={timeOfDayTypes[block.timeOfDay]}
              />
              {block.videoEffect && (
                <InfoRow title="Видеоэффект" value={block.videoEffect.name} />
              )}
            </>
          ) : block.map ? (
            <InfoRow title="2D карта" value={block.map.name} />
          ) : null}

          {(() => {
            switch (block.blockType) {
              case "two-lines":
                return (
                  <>
                    <InfoRow title="Первая строка" value={block.firstLine} />
                    {block.secondLine && (
                      <InfoRow title="Вторая строка" value={block.secondLine} />
                    )}
                    {block.icon && (
                      <InfoRow
                        title="Иконка"
                        value={findIconName(block.icon)}
                      />
                    )}
                  </>
                );
              case "three-lines":
                return (
                  <>
                    <InfoRow title="Первая строка" value={block.firstLine} />
                    {block.secondLine && (
                      <InfoRow title="Вторая строка" value={block.secondLine} />
                    )}
                    {block.thirdLine && (
                      <InfoRow title="Третья строка" value={block.thirdLine} />
                    )}
                    {block.icon && (
                      <InfoRow
                        title="Иконка"
                        value={findIconName(block.icon)}
                      />
                    )}
                    {block.accent && (
                      <InfoRow title="Акцент" value={block.accent} />
                    )}
                  </>
                );
              case "infographics":
                return <InfoRow title="Название" value={block.clip} />;
              case "sunrise-sunset":
                return (
                  <>
                    <InfoRow title="Тип" value={sunriseSunset[block.type]} />
                    <InfoRow title="Время" value={block.time} />
                    {block.additionalField === "one-line" && (
                      <InfoRow title="Первая строка" value={block.firstLine} />
                    )}
                    {block.additionalField === "change" && (
                      <>
                        <InfoRow
                          title="Первая строка"
                          value={block.firstLine}
                        />
                        <InfoRow
                          title="Вторая строка"
                          value={block.secondLine}
                        />
                        <InfoRow
                          title="Третья строка"
                          value={block.thirdLine}
                        />
                      </>
                    )}
                  </>
                );
              default:
                return null;
            }
          })()}
        </details>
      ))}
    </>
  );
}
