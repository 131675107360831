import Button from "emg-ui-kit/components/Button";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Modal from "emg-ui-kit/components/Modal";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import { cloneDeep } from "lodash";
import { nanoid } from "nanoid";
import React, { useCallback, useRef, useState } from "react";
import SunEditor from "suneditor-react";

import { Values } from "../order/m24/M24TableForm";
import { ColorButton } from "./ColorPicker";
import FlexContainer from "./FlexContainer";
import styles from "./TableEditorNew.module.css";
import { convertHtmlforUnity } from "./TagConverter";

const MINCOLUMN = 1;
const MANCOLUMN = 10;
const MAXROW = 10;

export interface Cell {
  id: string;
  editorsText: string;
  text: string;
  fontColor: string;
  textOrient?: string;
  cellColor?: string;
  image?: string;
}

export type TableData = {
  columnData: { width: number }[];
  rowData: Cell[][];
};

export function createCell(): Cell {
  return {
    id: nanoid(8),
    editorsText: "",
    text: "",
    textOrient: undefined,
    fontColor: "black",
    cellColor: "#ffffff",
    image: undefined,
  };
}

function Table({ widthEdit }: { widthEdit: boolean }) {
  const { values, setFieldValue } = useFormikContext<Values>();
  const { tableData } = values;

  const resizeData = useRef<{
    pageX?: number;
    curCol?: HTMLElement;
    nxtCol?: HTMLElement;
    curColWidth?: number;
    nxtColWidth?: number;
  }>({});

  const [editRowActive, setEditRowActive] = useState<number>();

  // const handleMouseDown = (event: React.MouseEvent) => {
  //   const curCol = (event.target as HTMLElement).parentElement!;
  //   const nxtCol = curCol.nextElementSibling
  //     ? (curCol.nextElementSibling as HTMLElement)
  //     : undefined;
  //   const pageX = event.pageX;
  //   const curColWidth = curCol.offsetWidth;
  //   let nxtColWidth: number | undefined;
  //   if (nxtCol) {
  //     nxtColWidth = nxtCol.offsetWidth;
  //   }
  //   resizeData.current = {
  //     curCol,
  //     nxtCol,
  //     pageX,
  //     curColWidth,
  //     nxtColWidth,
  //   };
  // };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (resizeData.current.curCol) {
      const diffX = event.pageX - resizeData.current.pageX!;

      if (resizeData.current.nxtCol)
        resizeData.current.nxtCol.style.width =
          resizeData.current.nxtColWidth! - diffX + "px";

      resizeData.current.curCol.style.width =
        resizeData.current.curColWidth! + diffX + "px";
    }
  };

  // const handleMouseUp = () => {
  //   resizeData.current = {};
  //   if (headerRowRef.current) {
  //     let colWidths = Array.from(
  //       headerRowRef.current.children,
  //       (el) => (el as HTMLElement).offsetWidth
  //     );
  //     colWidths = colWidths.slice(1, -1);
  //     const total = colWidths.reduce((acc, width) => acc + width);
  //     const colPercents = colWidths.map((width) => (width / total) * 100);
  //     updateData({ ...tableData, colWidths: colPercents });
  //   }
  // };

  const handleDeleteRow = (row: number) => {
    // console.log("handleDeleteRow values", values.tableData);
    const updatedData = { ...values };
    updatedData.tableData.rowData.splice(row, 1);
    setFieldValue("tableData", updatedData.tableData);
  };

  const handleEditRowActive = (row: number) => {
    if (row === editRowActive) {
      setEditRowActive(undefined);
    } else {
      setEditRowActive(row);
    }
  };

  const handleCheckTextOrient = useCallback((value: string): string => {
    const searchText = {
      center: "text-align: center",
      right: "text-align: right",
      justify: "text-align: justify",
    };
    for (const [key, text] of Object.entries(searchText)) {
      if (value.includes(text)) {
        return key;
      }
    }
    return "left";
  }, []);

  const handleUpdateCell = useCallback(
    (row: number, col: number, value: string) => {
      setFieldValue(`tableData.rowData.${row}.${col}.editorsText`, value);
      setFieldValue(
        `tableData.rowData.${row}.${col}.text`,
        convertHtmlforUnity(value)
      );
      setFieldValue(
        `tableData.rowData.${row}.${col}.textOrient`,
        handleCheckTextOrient(value)
      );
    },
    [handleCheckTextOrient, setFieldValue]
  );

  const heights = useRef<number[][]>([[]]);

  const addHeight = (rowIndex: number, colIndex: number) => {
    if (!heights.current[rowIndex]) {
      heights.current[rowIndex] = [];
    }
    return (element: HTMLDivElement | null) => {
      if (element) {
        heights.current[rowIndex][colIndex] = element.clientHeight;
      }
    };
  };

  return (
    <table
      className={styles.table}
      // ref={(element) => setTableHeight(element?.offsetHeight)}
      onMouseMove={handleMouseMove}
    >
      {/* <thead>
        <tr ref={headerRowRef} className={styles.headerRow}>
          <th className={styles.th} style={{ width: 20 }}>
            #
          </th>
          {tableData.rowData[0].map((header, idx) => {
            const maxRowsInValue = Math.max(
              ...tableData.rowData[0].map((value) => value.split("\n").length)
            );
            return (
              <th key={idx} className={styles.th}>
                <textarea
                  value={header}
                  // onChange={(event) =>
                  //   handleUpdateCell(0, idx, event.target.value)
                  // }
                  className={styles.textInput}
                  rows={maxRowsInValue}
                  wrap="off"
                />
                {idx !== tableData.data[0].length - 1 && (
                  <div
                    className={styles.resizeHandler}
                    style={{ height: tableHeight }}
                    onMouseDown={handleMouseDown}
                    // onMouseUp={handleMouseUp}
                  />
                )}
              </th>
            );
          })}
          <th className={styles.th} style={{ width: 20 }} />
        </tr>
      </thead> */}
      <tbody>
        {tableData.rowData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className={styles.td}>
              <div className={styles.div}>{rowIndex + 1}</div>
            </td>
            <td className={styles.td}>
              <div className={styles.div}>
                <button
                  className={
                    editRowActive === rowIndex
                      ? styles.editButtonActive
                      : styles.editButton
                  }
                  onClick={() => handleEditRowActive(rowIndex)}
                />
              </div>
            </td>
            {row.map((cell, colIndex) => {
              return (
                <td key={colIndex} className={styles.td}>
                  {editRowActive === rowIndex && (
                    <div className={styles.toolBarContainer}>
                      <ColorButton
                        colors={["white", "Orange", "RoyalBlue", "SaddleBrown"]}
                        pickerPosition="left"
                        updateColor={(color: string) =>
                          setFieldValue(
                            `tableData.rowData.${rowIndex}.${colIndex}.cellColor`,
                            color
                          )
                        }
                        className={styles.cellColorButton}
                        tooltip="Цвет фона"
                      />
                      <ColorButton
                        colors={["Black", "Orange", "RoyalBlue", "SaddleBrown"]}
                        pickerPosition="left"
                        updateColor={(color: string) =>
                          setFieldValue(
                            `tableData.rowData.${rowIndex}.${colIndex}.fontColor`,
                            color
                          )
                        }
                        className={styles.fontColorButton}
                        tooltip="Цвет текста"
                      />
                      {/* <div className={styles.imageAddButton}></div> */}
                    </div>
                  )}
                  <div
                    className={styles.sunEditorContainer}
                    ref={addHeight(rowIndex, colIndex)}
                  >
                    <SunEditor
                      key={cell.id}
                      hideToolbar={editRowActive !== rowIndex}
                      height="auto"
                      width={`${tableData.columnData[colIndex].width * 10}px`}
                      lang="ru"
                      setDefaultStyle={`padding: 5px; font-size: 18px; background-color: ${tableData.rowData[rowIndex][colIndex].cellColor}; color: ${tableData.rowData[rowIndex][colIndex].fontColor};`}
                      setOptions={{
                        minHeight: "auto",
                        buttonList: [["fontSize", "bold", "align"]],
                      }}
                      onChange={(value: string) => {
                        handleUpdateCell(rowIndex, colIndex, value);
                      }}
                      defaultValue={cell.editorsText}
                    />
                  </div>
                </td>
              );
            })}
            <td className={styles.td}>
              <div className={styles.div}>
                <button
                  className={styles.deleteButton}
                  onClick={() => handleDeleteRow(rowIndex)}
                  disabled={tableData.rowData.length === 1}
                />
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface TableEditorProps {
  // tableData: TableData;
  updateData: (tableData: TableData) => void;
  visible: boolean;
  close: () => void;
  defaultTableConstructor: () => TableData;
  additionalInfo?: string;
}

export default function TableEditor({
  // tableData,
  updateData,
  visible,
  close,
  defaultTableConstructor,
  additionalInfo,
}: TableEditorProps) {
  const { values, setFieldValue } = useFormikContext<Values>();
  const { tableData } = values;
  const [widthEdit, setWidthEdit] = useState(false);
  const [columnData, setColumnData] = useState<{ width: number }[]>(
    cloneDeep(tableData.columnData)
  );

  const currentRowCount = tableData.rowData.length;

  const addRow = useCallback(() => {
    const colCount = tableData.columnData.length;
    const newRow = Array.from({ length: colCount }, () => ({
      ...createCell(),
    }));
    const updatedData = { ...values };
    updatedData.tableData.rowData.push(newRow);
    setFieldValue("tableData", updatedData.tableData);
  }, [tableData, values, setFieldValue]);

  const changeColCount = useCallback(
    (nextColCount: number) => {
      const prevColCount = tableData.columnData.length;

      if (nextColCount < MINCOLUMN || nextColCount > MANCOLUMN) return;

      const updatedData = { ...values };
      function getNewWidth(): { width: number } {
        return { width: parseFloat((100 / nextColCount).toFixed(2)) };
      }

      if (nextColCount < prevColCount) {
        const modifiedcolumnData = updatedData.tableData.columnData
          .slice(0, -1)
          .map(() => getNewWidth());
        updatedData.tableData.columnData = modifiedcolumnData;
        const modifiedRowData = updatedData.tableData.rowData.map((row) =>
          row.slice(0, -1)
        );
        updatedData.tableData.rowData = modifiedRowData;
      } else {
        const modifiedColumnData = Array.from({ length: nextColCount }, () =>
          getNewWidth()
        );
        updatedData.tableData.columnData = modifiedColumnData;

        const modifiedRowData = updatedData.tableData.rowData.map((row) => [
          ...row,
          createCell(),
        ]);
        updatedData.tableData.rowData = modifiedRowData;
      }
      setFieldValue("tableData", updatedData.tableData);
    },
    [tableData, values, setFieldValue]
  );

  const changeColumnWidth = (value: number, index: number) => {
    const previousWidths = columnData.slice(0, index).map((item) => item.width);
    const previousWidthsSum = previousWidths.reduce(
      (acc, curr) => acc + curr,
      0
    );
    if (isNaN(value) || value < 1 || value > 95 - previousWidthsSum) return;

    const restColomnsWidthSum = 100 - value - previousWidthsSum;
    const restColomns = columnData.length - (index + 1);
    const newRestColomnsWidth = parseFloat(
      (restColomnsWidthSum / restColomns).toFixed(2)
    );
    const updatedData = [...columnData];
    updatedData[index].width = value;
    for (let i = index + 1; i < updatedData.length; i++) {
      updatedData[i].width = newRestColomnsWidth;
    }
    setColumnData(updatedData);
  };

  const handleWidthEdit = (value: boolean) => {
    setWidthEdit(value);
    value && setColumnData(cloneDeep(tableData.columnData));
  };

  const setColumnsWidth = () => {
    setFieldValue("tableData.columnData", cloneDeep(columnData));
  };

  console.log("valuesFormik", values);

  return (
    <Modal visible={visible} close={close}>
      <div className={styles.mainContainer}>
        <FlexContainer>
          <Field
            id="Количество столбцов"
            as={TextField}
            label="Количество столбцов"
            labelStyle={{ maxWidth: 180 }}
            style={{ maxWidth: 260 }}
            value={tableData.columnData.length}
            onChange={(event: any) => changeColCount(event.target.value)}
            type="number"
            disabled={widthEdit}
          />
          <Checkbox
            label="Изменить ширину столбцов"
            checked={widthEdit}
            setChecked={(value) => handleWidthEdit(value)}
            style={{ marginLeft: 20, marginTop: 8 }}
          />
        </FlexContainer>
        {widthEdit && (
          <table>
            <tbody>
              <tr>
                {columnData.map((column, index) => (
                  <td
                    key={index}
                    className={styles.td}
                    style={{ width: `${column.width * 10}px` }}
                  >
                    <input
                      type="number"
                      value={column.width}
                      className={styles.textInput}
                      onChange={(event: any) =>
                        changeColumnWidth(parseFloat(event.target.value), index)
                      }
                      disabled={index + 1 === columnData.length}
                    />
                  </td>
                ))}
                <td>
                  <button onClick={() => setColumnsWidth()}>OK</button>
                </td>
              </tr>
            </tbody>
          </table>
        )}
        <Table widthEdit={widthEdit} />
        <br />
        <button
          className={styles.addButton}
          onClick={addRow}
          disabled={currentRowCount >= MAXROW}
        >
          Добавить строку
        </button>
        <div className={styles.buttonContainer}>
          <Button
            title="Сохранить"
            onClick={close}
            style={{ marginRight: 20 }}
          />
          <Button
            color="red"
            title="Сбросить таблицу"
            onClick={() => updateData(defaultTableConstructor())}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
}
