import React from "react";

import {
  timeOfDayTypes,
  atmoWeatherTypes,
  atmoNumberBlockTypes,
  atmoInfoBlockTypes,
  interactiveVariants,
  sunriseSunset,
  windDirections,
} from "../../../order/atmosphere/constants";
import { findIconName } from "../../../order/atmosphere/utils";
import styles from "./AtmoSubTable.module.css";

interface SubTableProps {
  item: Record<string, any>;
}

function InfoRow({ title, value }: { title: string; value: string }) {
  return (
    <div className={styles.row}>
      <div className={styles.infoTitle}>{title}:</div>
      <div className={styles.infoDescription}>{value}</div>
    </div>
  );
}

const SubTable: React.FC<SubTableProps> = ({ item }) => {
  return (
    <div className={styles.subtable}>
      <div className={styles.infoRow}>
        <InfoRow
          title="Ручная сборка"
          value={item.data.manual ? "Да" : "Нет"}
        />
        {item.data.enableSound && (
          <InfoRow
            title="Включить звук"
            value={item.data.enableSound ? "Да" : "Нет"}
          />
        )}
        <InfoRow
          title="Отправлено на эфир"
          value={item.feedback.sentToBroadcast ? "Да" : "Нет"}
        />
        <InfoRow title="Время эфира" value={item.data.sheduleOfBroadcast} />
      </div>
      <table className={styles.table}>
        <thead>
          <tr className={styles.tableHeader}>
            <th>№</th>
            <th>Хр.</th>
            <th>Тип блока/Данные</th>
            <th>Тип графики</th>
            <th>Погода</th>
            <th>Время суток</th>
          </tr>
        </thead>
        <tbody>
          {item.data.blocks.map((block: any, index: any) => (
            <tr key={index} className={styles.tableBody}>
              <th>{index + 1}</th>
              <th>{block?.timing}</th>
              <th>
                {block.blockType && (
                  <div style={{ fontWeight: "bold" }}>
                    {atmoNumberBlockTypes[block.blockType] ||
                      atmoInfoBlockTypes[block.blockType]}
                  </div>
                )}
                {block.comment && <div>Комментарий: {block.comment}</div>}

                {block.additionalField === "icon-right" &&
                  block.blockType === "three-lines" && (
                    <div>Иконка: {findIconName(block.icon)}</div>
                  )}
                {block.additionalField === "icon-right" &&
                  block.blockType !== "three-lines" && (
                    <div>Иконка справа: {findIconName(block.icon)}</div>
                  )}
                {block.additionalField === "icon-left" &&
                  block.blockType !== "three-lines" && (
                    <div>Иконка слева: {findIconName(block.icon)}</div>
                  )}

                {["text", "two-lines", "three-lines"].includes(
                  block.blockType
                ) && (
                  <>
                    {["firstLine", "secondLine", "thirdLine"].map(
                      (number, index) =>
                        block[number] && (
                          <div key={number}>
                            <div className={styles.strings}>
                              {index + 1} ст:
                            </div>{" "}
                            {block[number]}{" "}
                            {block[`${number}Color`] && (
                              <div
                                className={
                                  block[`${number}Color`] !== "transparent"
                                    ? styles.colorSquare
                                    : styles.colorSquareLine
                                }
                                style={{
                                  backgroundColor: block[`${number}Color`],
                                }}
                              ></div>
                            )}
                          </div>
                        )
                    )}
                  </>
                )}

                {block.additionalField === "accent" && block.accent && (
                  <div>Акцент: {block.accent}</div>
                )}

                {block.blockType === "feelsLike" && (
                  <div>Температура: {block.temperature}</div>
                )}
                {block.blockType === "wind" && (
                  <>
                    <div>Направление ветра: {block.windDirection}</div>
                    <div>Скорость ветра: {block.windSpeed}</div>
                  </>
                )}
                {block.blockType === "pressure" && (
                  <div>Давление: {block.pressure}</div>
                )}
                {block.blockType === "forecast" && (
                  <>
                    <div>Время прогноза: {block.forecastTime}</div>
                    <div>Температура: {block.temperature}</div>
                    <div>Подпись: {block.forecastDescription}</div>
                  </>
                )}
                {block.blockType === "sunrise-sunset" && (
                  <>
                    <div>{sunriseSunset[block.type]}</div>
                    <div>Время: {block.time}</div>
                    {block?.firstLine && <div>1 ст: {block.firstLine}</div>}
                    {block?.secondLine && <div>2 ст: {block.secondLine}</div>}
                    {block?.thirdLine && <div>3 ст: {block.thirdLine}</div>}
                  </>
                )}
              </th>
              <th>
                {block.mapVariant && (
                  <div style={{ fontWeight: "bold" }}>
                    {block.mapVariant === "interactive"
                      ? "Интерактивная"
                      : block.mapVariant === "2D"
                      ? ""
                      : block.mapVariant}
                  </div>
                )}
                {interactiveVariants[block.interactiveVariant]}
                {block?.map?.name}
                {block?.location?.name}
                {block?.clip}
                <div>{block?.videoEffect?.name}</div>
                <div>{windDirections[block.interactiveWindDirection]}</div>
              </th>
              <th>
                {atmoWeatherTypes[block.weather]}
                {block.blockType === "infographics" && "—"}
                {block.mapVariant === "interactive" && "—"}
                {block.mapVariant === "2D" && "—"}
              </th>
              <th>
                {timeOfDayTypes[block.timeOfDay]}
                {block.blockType === "infographics" && "—"}
                {block.mapVariant === "2D" && "—"}
              </th>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SubTable;
