import Blocks from "emg-ui-kit/components/Blocks";
import EditorField from "emg-ui-kit/components/EditorField";
import { HtmlEditor2 } from "emg-ui-kit/components/HtmlEditor2";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import { nanoid } from "nanoid";
import React, { useMemo, useState } from "react";
import { useDispatch } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { convertHtmlforUnity } from "../../common/TagConverter";
import { FormProps } from "../../common/models";
import { useRemoveBackground } from "../../common/useRemoveBackground";
import {
  convertImageUrlToFile,
  convertToSelectOptions,
  useIsDesktop,
} from "../../common/utils";
import RmBgModal from "../RmBgModal";
import messages from "../messages";
import usePersons from "../usePersons";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  IMAGE_TYPES,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
  removeTouched,
  getDeepValidationProps,
} from "../util";
import { SITES, SOURCES, THEMES, THEMES_FULL } from "./constants";

const IMAGE_ASPECT = 700 / 845;
const FONT_SIZES = [
  13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31,
  32, 33, 34, 36, 38, 40, 42, 44, 46,
];
type Item = {
  text: string;
  timing: number | string;
  key: string;
  editorsText: string;
};

type Values = ReturnType<typeof getInitialValues>;

function createItem(): Item {
  return { text: "", timing: 5, key: nanoid(), editorsText: "" };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    image: initialFormData?.image as FormImage | undefined,
    author: (initialFormData?.author ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    positionEditorsTexts: (initialFormData?.positionEditorsTexts ??
      "") as string,
    site: (initialFormData?.site in SITES
      ? initialFormData?.site
      : "") as string,
    customSite: (initialFormData?.site === "custom"
      ? initialFormData?.customSite ?? ""
      : "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 30) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? initItems()) as Item[],
  };
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

function validate(values: Values) {
  const errors = {
    name: values.name
      ? validateIncorrectFormat(values.name, CLIP_NAME_REGEXP)
      : undefined,
    theme: validateNotEmpty(values.theme),
    author: validateNotEmpty(values.author),
    customSite:
      values.site === "custom"
        ? validateNotEmpty(values.customSite)
        : undefined,
    image: values.image
      ? validateAspect(values.image, IMAGE_ASPECT)
      : undefined,
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
          validatePositive(+values.timing) ??
          validateMax(+values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      text: (() => {
        const div = document.createElement("div");
        div.innerHTML = item.text;
        return div.textContent?.match(/\S/) ? undefined : messages.empty;
      })(),
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
            validatePositive(+item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    ...pick(
      values,
      "name",
      "image",
      "author",
      "position",
      "positionEditorsTexts",
      "site",
      "customSite",
      "source",
    ),
    theme: THEMES_FULL[values.theme],
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items.map((block) => ({
      text: block.text,
      editorsText: block.editorsText,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function PersonQuoteForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validate,
    onSubmit: (values) => onSubmit(prepareData(values)),
  });

  const { touched, errors, setFieldValue, setFieldTouched, setTouched } =
    formik;
  const dispatch = useDispatch();
  const isDesktop = useIsDesktop();
  const { persons, person, handleUpdatePerson } = usePersons("photoRect");

  const personsOptions = useMemo(
    () => persons?.map((person, id) => ({ id, value: person.name })) ?? [],
    [persons],
  );

  const [modalVisible, setModalVisible] = useState(false);

  usePreview(
    channel,
    template,
    +formik.values.timing,
    formik.values,
    prepareData,
  );

  const validationUtils = new ValidationPropsUtils(touched, errors);

  const handleImageUpdate = (image?: FormImage) => {
    setFieldTouched("image");
    setFieldValue("image", image);
  };

  const handleSelect = (option?: Option) => {
    handleUpdatePerson(option);
    const person = persons?.find((person) => person.name === option?.value);
    formik.setFieldValue("author", person?.name ?? "");
    if (person) {
      convertImageUrlToFile(person.photoRect)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err.message });
        });
    } else {
      handleImageUpdate(undefined);
    }
  };

  const removeBackground = useRemoveBackground({
    setModalVisible,
    image: formik.values.image,
    handleImageUpdate,
  });

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    values: formik.values,
    prepareData,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <RmBgModal
          modalVisible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
        />
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...validationUtils.getProps("name")}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          required
          {...validationUtils.getProps("theme")}
        />
        <SingleSearch
          label="Выбрать из базы"
          selected={person}
          updateSelected={handleSelect}
          options={personsOptions}
        />
        <ImageInput
          aspect={IMAGE_ASPECT}
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={handleImageUpdate}
          {...validationUtils.getProps("image")}
          style={{ marginLeft: isDesktop ? 210 : 0 }}
          additionalActions={[{ name: "Убрать фон", action: removeBackground }]}
        />
        <Field
          as={TextField}
          label="Автор"
          name="author"
          required
          {...validationUtils.getProps("author")}
        />
        <EditorField
          fontSize={FONT_SIZES}
          label="Должность"
          editorOptions={{ height: 80 }}
          onChange={(value) => {
            setFieldValue("position", convertHtmlforUnity(value));
            setFieldValue("positionEditorsTexts", value);
          }}
          defaultValue={formik.values.positionEditorsTexts}
          buttonList={[["fontSize"]]}
        />
        <Field
          as={Select}
          name="site"
          label="Сайт"
          options={convertToSelectOptions(SITES)}
          defaultOptionAvailable
          placeholder="-- Без сайта --"
        />
        {formik.values.site === "custom" && (
          <Field
            as={TextField}
            label="Другой сайт"
            name="customSite"
            required
            {...validationUtils.getProps("customSite")}
          />
        )}
        <Field
          as={TextField}
          label="Источник"
          name="source"
          dataList={SOURCES}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждого слайда" },
          ]}
        />
        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Blocks
          items={formik.values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index) => (
            <>
              {formik.values.timingType === "separate" && (
                <Field
                  as={TextField}
                  name={`items.${index}.timing`}
                  type="number"
                  {...validationUtils.getDeepProps(`items.${index}.timing`)}
                  label="Хронометраж (сек.)"
                  labelStyle={{ maxWidth: 180 }}
                />
              )}
              <HtmlEditor2
                fontSize={FONT_SIZES}
                key={item.key + index}
                name={`items.${index}.text`}
                onChange={(value: string) => {
                  setFieldValue(
                    `items.${index}.text`,
                    convertHtmlforUnity(value),
                  );
                  setFieldValue(`items.${index}.editorsText`, value);
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.text`, true);
                }}
                defaultValue={item?.editorsText}
                {...getDeepValidationProps(
                  `items[${index}].text]`,
                  touched,
                  errors,
                )}
                buttonList={[["fontSize", "bold", "italic", "removeFormat"]]}
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(PersonQuoteForm);
