import { FormImage, getImageFrom } from "emg-ui-kit/components/ImageInput";
import { useMediaQuery } from "react-responsive";

export const dateFormatter = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});

export function convertUrlToFile(url: string): Promise<File> {
  return fetch(url)
    .then((resp) => resp.blob())
    .then((blob) => {
      const filename = url.split("/").pop();
      return new File([blob], filename!);
    });
}

export function convertImageUrlToFile(
  url: string
): Promise<FormImage | undefined> {
  return convertUrlToFile(url)
    .then(getImageFrom)
    .catch(() => undefined);
}

export function useIsDesktop() {
  const DESKTOP_BREAKPOINT = "(min-width: 601px)";
  return useMediaQuery({ query: DESKTOP_BREAKPOINT });
}

export function useShouldRenderTable() {
  const TABLE_BREAKPOINT = "(min-width: 1000px)";
  return useMediaQuery({ query: TABLE_BREAKPOINT });
}

export function convertToSelectOptions(map: Record<string, string>) {
  return Object.entries(map).map(([id, name]) => ({ id, name }));
}

export function extractTextContent(html: string) {
  const element = document.createElement("div");
  element.innerHTML = html
    .replace(/<\/p>/g, "</p>\n")
    .replace(/<\/li>/g, "</li>\n");
  const text = element.textContent;
  return text && /\S/.test(text) ? text : "—";
}
