import Checkbox from "emg-ui-kit/components/Checkbox";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { updateOrderFeedbackThunk } from "../../../redux/tasks";

interface Props {
  initialFeedback?: {
    sentToBroadcast: boolean;
    renderError: boolean;
    incorrectData: boolean;
  };
  id: string;
}
export default function TaskFeedback({
  initialFeedback = {
    sentToBroadcast: false,
    renderError: false,
    incorrectData: false,
  },
  id,
}: Props) {
  const [state, setState] = useState(initialFeedback);

  const dispatch = useDispatch();

  const isFirstRender = useRef<boolean>(true);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  useEffect(() => {
    if (!isFirstRender.current) {
      timer.current = setTimeout(() => {
        dispatch(updateOrderFeedbackThunk({ id, feedback: state }));
      }, 700);
    }
    isFirstRender.current = false;
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, [dispatch, id, state]);

  return (
    <>
      <Checkbox
        label="Отправлено на эфир"
        checked={state.sentToBroadcast ? state.sentToBroadcast : false}
        setChecked={(val) => {
          setState((prev) => ({
            ...prev,
            sentToBroadcast: val,
          }));
        }}
      />
      <Checkbox
        label="Ошибка рендера"
        checked={state.renderError ? state.renderError : false}
        setChecked={(val) => {
          setState((prev) => ({
            ...prev,
            renderError: val,
          }));
        }}
      />
      <Checkbox
        label="Некорректные данные"
        checked={state.incorrectData ? state.incorrectData : false}
        setChecked={(val) => {
          setState((prev) => ({
            ...prev,
            incorrectData: val,
          }));
        }}
      />
    </>
  );
}
