const messages = {
  empty: "Не должно быть пустым",
  email: "Должно быть валидным email",
  url: "Должно быть валидным URL",
  number: "Должно быть числом",
  integer: "Должно быть целым",
  negative: "Не должно быть отрицательным",
  positive: "Должно быть положительным",
  nonPositive: "Не должно быть больше 0",
  sumNotOneHundred: "Сумма значений должна равняться 100",
  min: (limit: number) => `Не должно быть меньше ${limit}`,
  max: (limit: number) => `Не должно быть больше ${limit}`,
  range: (min: number, max: number) =>
    `Не соответствует заданному диапазону от ${min} до ${max}`,
  atLeastOneItem: "Должно содержать хотя один блок",
  aspect: "Некорректное соотношение сторон. Обрежьте изображение",
  temperature: "Формат температуры: +15 или -3",
  speed: "Формат скорости: 18 или 7–8",
  minChars: (limit: number) => `Не должно быть меньше ${limit} символов`,
  maxChars: (limit: number) => `Не должно быть больше ${limit} символов`,
  maxLines: (limit: number) => `Не более ${limit} строк`,
  maxCharsInLine: (limit: number) => `Не более ${limit} символов в строке`,
  incorrectFormat: "Некорректный формат",
  timingSum: (limit: number) =>
    `Суммарный хронометраж не должен превышать ${limit} секунд`,
  delaysSum: (sum: number, limit: number) =>
    `Суммарная задержка ${sum} секунд, не должна превышать суммарный хронометраж ${limit} секунд`,
};

export default messages;
