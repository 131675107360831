import { FormImage } from "emg-ui-kit/components/ImageInput";
import cloneDeep from "lodash/cloneDeep";

import { pick } from "../order/util";
import { IFilterState } from "../tasks/components/Filters/FilterTypes";
import { ForceTaskStatus, Task as Order } from "./models";

export function uploadFileGetUrl(file: File) {
  let fileFormData = new FormData();
  fileFormData.append("file", file);
  return makeRequest<{ url: string }>("/api/v2/upload", {
    method: "POST",
    body: fileFormData,
  });
}

async function convertDataToSend(data: any) {
  const dataToSend = cloneDeep(data);
  try {
    async function replaceFileFields(obj: any) {
      for (let key in obj) {
        if (obj[key] instanceof File) {
          let result = await uploadFileGetUrl(obj[key]);
          obj[key] = result.url;
        } else if (typeof obj[key] === "object") {
          await replaceFileFields(obj[key]);
        }
      }
    }
    await replaceFileFields(dataToSend);

    return dataToSend;
  } catch (error) {
    throw new Error(
      "Произошла ошибка при отправке файла, попробуйте отправить повторно"
    );
  }
}

export async function addOrder({
  channel,
  template,
  data,
}: {
  channel: string;
  template: string;
  data: Record<string, any>;
}) {
  const dataToSend = await convertDataToSend({ ...data });

  return makeRequest<{ id: string }>("/api/v2/orders", {
    method: "POST",
    body: JSON.stringify({ channel, template, data: dataToSend }),
    headers: { "Content-Type": "application/json" },
  });
}

export async function addDraft({
  channel,
  template,
  data,
}: {
  channel: string;
  template: string;
  data: Record<string, any>;
}) {
  const dataToSend = await convertDataToSend({ ...data });

  return makeRequest<{ id: string }>("/api/v2/drafts", {
    method: "POST",
    body: JSON.stringify({ channel, template, data: dataToSend }),
    headers: { "Content-Type": "application/json" },
  });
}

export async function updateDraft({
  channel,
  template,
  data,
}: {
  channel: string;
  template: string;
  data: Record<string, any>;
}) {
  const dataToSend = await convertDataToSend({ ...data });

  return makeRequest<{ id: string }>(`/api/v2/drafts/${data.draftId}`, {
    method: "POST",
    body: JSON.stringify({ channel, template, data: dataToSend }),
    headers: { "Content-Type": "application/json" },
  });
}

export function cancelOrder(orderId: string) {
  return makeRequest<Order>(`/api/v2/orders/${orderId}`, {
    method: "PATCH",
    body: JSON.stringify({
      status: "failed",
      message: "Отменено пользователем",
    }),
    headers: { "Content-Type": "application/json" },
  });
}

export function forceUpdateOrder(orderId: string, status: ForceTaskStatus) {
  return makeRequest<Order>(`/api/v2/orders/${orderId}`, {
    method: "PATCH",
    body: JSON.stringify({
      status: status,
      isForceUpdate: true,
    }),
    headers: { "Content-Type": "application/json" },
  });
}

export function updateOrderFeedback(
  orderId: string,
  feedback: Order["feedback"]
) {
  return makeRequest<Order>(`/api/v2/orders/feedback/${orderId}`, {
    method: "PATCH",
    body: JSON.stringify({
      feedback,
    }),
    headers: { "Content-Type": "application/json" },
  });
}

export function acceptOrder(orderId: string) {
  return makeRequest<Order>(`/api/v2/orders/${orderId}`, {
    method: "PATCH",
    body: JSON.stringify({
      status: "inProgress",
    }),
    headers: { "Content-Type": "application/json" },
  });
}

export function finishOrder(orderId: string, outpath?: string) {
  return makeRequest<Order>(`/api/v2/orders/${orderId}`, {
    method: "PATCH",
    body: JSON.stringify({
      status: "done",
      progress: 100,
      outpath,
    }),
    headers: { "Content-Type": "application/json" },
  });
}

export function getOrders(filterState: IFilterState) {
  let url = `/api/v2/orders/by_filters`;
  return makeRequest<{ total: number; orders: Order[] }>(url, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(filterState),
  });
}

export function getOrder(id: string) {
  return makeRequest<Order>(`/api/v2/orders/${id}`);
}

type UserTypes = "admin" | "employee" | "client" | "service";

export type UserInfo = {
  email: string;
  type: UserTypes;
  groups: string[];
  catalogUrl?: string;
};

export type UserInfoExtended = {
  isAuthenticated: boolean;
  userInfo?: UserInfo;
};

export function checkStatus() {
  return makeRequest<UserInfoExtended>("/check");
}

export function login(username: string, password: string) {
  const credentialParams = Object.entries({ username, password })
    .map(
      ([key, value]) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    )
    .join("&");
  return makeRequest<UserInfo>("/login", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: credentialParams,
  });
}

export function register(userData: { email: string; password: string }) {
  const credentialParams = Object.entries({
    username: userData.email,
    password: userData.password,
  })
    .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
    .join("&");
  return makeRequest<UserInfo>("/register", {
    method: "POST",
    headers: {
      "Content-Type": "application/x-www-form-urlencoded",
    },
    body: credentialParams,
  });
}

export function logout() {
  return makeRequest("/logout");
}

export type NotificationCategories = "none" | "info" | "warning" | "error";

export type Notification = {
  notification: { category: NotificationCategories; message: string };
  troubleNotification: {
    unityMessage: string;
    aeMessage: string;
    unityOn: boolean;
    aeOn: boolean;
  };
};

export function getNotification() {
  return makeRequest<Notification>("/api/v2/notification");
}

export function updateNotification(notification: Notification) {
  return makeRequest<Notification>("/api/v2/notification", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(notification),
  });
}

export type EmailNotificationParams = {
  intervalMinutes: number;
  notificationsEnabled: boolean;
};

export function getEmailNotificationsParams() {
  return makeRequest<EmailNotificationParams>("/api/v2/emailnotifications");
}

export function updateEmailNotificationsParams(
  params: EmailNotificationParams
) {
  return makeRequest<EmailNotificationParams>("/api/v2/emailnotifications", {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(params),
  });
}

export type Infographic = {
  name: string;
  path: string;
  timing: string;
  overlap: [number, number];
};

export type LocationInfo = {
  name: string;
  geotag: string;
  path: string;
  availableVariants: Record<string, string[]>;
};

export type AtmosphereFormParams = {
  weatherTypes: string[];
  shedulesOfBroadcast: string[];
  timesOfDay: Record<string, string>;
  locationTypes: Record<string, LocationInfo[]>;
  mapTypes: { name: string; path: string }[];
  infographics: Infographic[];
  videoEffects: { name: string; path: string }[];
  updated: string;
};

export function getAtmosphereParams() {
  return makeRequest<AtmosphereFormParams>("/api/v2/atmosphere-params");
}

export function updateAtmosphereParams() {
  return makeRequest<AtmosphereFormParams>("/api/v2/atmosphere-params", {
    method: "PATCH",
  });
}

export const statusTexts: Record<number, string | undefined> = {
  400: "Некорректные данные",
  401: "Вы не авторизованы",
  403: "Отказано в доступе",
  404: "Не найдено",
  500: "Ошибка сервера",
};

export class ApiError extends Error {
  code: string;

  constructor(status: number, message?: string) {
    const msgOb = { msg: "" };
    msgOb.msg = message ?? statusTexts[status] ?? "Неизвестная ошибка";
    if (status === 502) {
      msgOb.msg = statusTexts[500] as string;
    }
    super(msgOb.msg);
    this.code = status.toString();
    this.name = this.constructor.name;
  }
}

export function isNotAuthorized(err: any) {
  return (
    typeof err === "object" && err.name === ApiError.name && err.code === "401"
  );
}

type GenericResponse = { message: string };

export async function makeRequest<T = GenericResponse>(
  url: string,
  opts?: RequestInit
): Promise<T> {
  const resp = await fetch(window.location.origin + url, {
    credentials: "include",
    ...opts,
  });
  if (resp.ok) {
    return resp.json();
  } else {
    const message = await resp.text();
    throw new ApiError(resp.status, message);
  }
}

export type PhotoTypes =
  | "photo"
  | "photoAlpha"
  | "photoRect"
  | "photoM24"
  | "photoVip";

export interface Person {
  id: string;
  name: string;
  position?: string;
  photo: string;
  photoAlpha: string;
  photoRect: string;
  photoM24: string;
  photoVip: string;
}

export function getPersons(photoType?: PhotoTypes) {
  const query = photoType ? `?photoType=${photoType}` : "";
  return makeRequest<Person[]>(`/api/v2/persons${query}`);
}

export function getPerson(id: string) {
  return makeRequest<Person>(`/api/v2/persons/${id}`);
}

export interface PersonData {
  name: string;
  position?: string;
  photo?: FormImage;
  photoAlpha?: FormImage;
  photoRect?: FormImage;
  photoM24?: FormImage;
  photoVip?: FormImage;
}

export function createPerson(personData: PersonData) {
  const formData = new FormData();

  for (const key of Object.keys(personData)) {
    const value = personData[key as keyof PersonData];
    if (value) {
      formData.append(key, value);
    }
  }

  return makeRequest<Person>("/api/v2/persons", {
    method: "POST",
    body: formData,
  });
}

export function updatePerson(personData: { id: string } & PersonData) {
  const { id, ...values } = personData;
  const formData = new FormData();

  for (const key of Object.keys(values)) {
    const value = personData[key as keyof PersonData];
    if (value) {
      formData.append(key, value);
    }
  }
  return makeRequest<Person>(`/api/v2/persons/${id}`, {
    method: "PATCH",
    body: formData,
  });
}

export function deletePerson(id: string) {
  return makeRequest<Person>(`/api/v2/persons/${id}`, { method: "DELETE" });
}

export interface User {
  id: string;
  email: string;
  type: UserTypes;
  groups: string[];
  catalogUrl: string;
}

export function getCsv(months: string) {
  return makeRequest<string>(`/api/v2/orders/csv?months=${months}`);
}

export function getUsers() {
  return makeRequest<User[]>(`/api/v2/users`);
}

export function getUser(id: string) {
  return makeRequest<User>(`/api/v2/users/${id}`);
}

export function createUser(userData: {
  email: string;
  password: string;
  type: string;
  group?: string;
  catalogUrl?: string;
}) {
  return makeRequest<User>(`/api/v2/users`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(userData),
  });
}

export function updateUser(userData: {
  id: string;
  type?: string;
  group?: string;
  catalogUrl?: string;
}) {
  return makeRequest<User>(`/api/v2/users/${userData.id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(pick(userData, "type", "group", "catalogUrl")),
  });
}

export function deleteUser(id: string) {
  return makeRequest<User>(`/api/v2/users/${id}`, { method: "DELETE" });
}

export function getDownloadLink(id: string, preview = false) {
  let url = `/api/v2/orders/${id}/download`;
  if (preview) {
    url += "?preview";
  }
  return makeRequest<string>(url);
}

export function removeBackgroundFrom(image: File) {
  const formData = new FormData();
  formData.append("image", image);
  return makeRequest<string>("/api/v2/rembg", {
    method: "POST",
    body: formData,
  });
}

export interface NewsArticle {
  _id: string;
  content: string;
  group: string;
  date: string;
}

export function getNews(group?: string) {
  const url = group ? `/api/v2/news?group=${group}` : `/api/v2/news`;
  return makeRequest<NewsArticle[]>(url);
}

export function createNewsArticle(articleData: {
  content: string;
  group: string;
}) {
  return makeRequest<NewsArticle>(`/api/v2/news`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(articleData),
  });
}

export function updateNewsArticle({
  id,
  ...articleData
}: {
  id: string;
  content: string;
  group: string;
}) {
  return makeRequest<NewsArticle>(`/api/v2/news/${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(articleData),
  });
}

export function getNewsArticle(id: string) {
  return makeRequest<NewsArticle>(`/api/v2/news/${id}`);
}

export function deleteNewsArticle(id: string) {
  return makeRequest<NewsArticle>(`/api/v2/news/${id}`, { method: "DELETE" });
}

export function getDrafts(
  order: string,
  page: number,
  perPage: number,
  loadAll?: boolean
) {
  let url = `/api/v2/drafts?order=${order}&page=${page}&perPage=${perPage}`;
  if (loadAll) url += "&loadAll";
  return makeRequest<{ total: number; orders: Order[] }>(url);
}

export function deleteDraftById(id: string) {
  return makeRequest<{ id: string }>(`/api/v2/drafts/${id}`, {
    method: "DELETE",
  });
}

export function makeSearch(text: string) {
  return makeRequest<{ orders: Order[]; total: any }>("/api/v2/search", {
    method: "POST",
    body: JSON.stringify({ search: text }),
    headers: { "Content-Type": "application/json" },
  });
}

export type DashBoardMapData = Record<string, number>;

export function getDashBoardMapData(options?: string) {
  const query = options ? options : "";
  return makeRequest<DashBoardMapData>(
    `/api/v2/dashboard/interactive?${query}`
  );
}

export function getTotalAtmoBroadcast(options?: string) {
  const query = options ? options : "";
  return makeRequest<{ total: number }>(
    `/api/v2/dashboard/totalAtmoBroadcast?${query}`
  );
}

export type DashBoardLocationsData = Record<string, Record<string, number>>;

export function getDashBoardLocationsData(options?: string) {
  const query = options ? options : "";
  return makeRequest<DashBoardLocationsData>(
    `/api/v2/dashboard/locations?${query}`
  );
}

export async function getBitrixTemplates() {
  return await makeRequest<BitrixCustomerAvailableTemplates[]>(
    `/api/v2/bitrix`
  );
}

export type Graphic = {
  id: number;
  id_cloud_render: string;
  name: string;
};

export type BitrixCustomerAvailableTemplates = {
  id: number;
  name: string;
  graphics: Graphic[];
};
